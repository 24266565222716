<template>
  <!-- Begin Hero Area -->
  <div
    class="hero-area hero-bg hero-style-5"
    data-bg-image=""
    :style="{ backgroundImage: `url(${bgImage})` }"
  >
    <div class="container hero-container">
      <div class="hero-item">
        <div class="hero-content white-text">
          <h1 class="title">
            Mobilize your research. Study your partipants anywhere in the world.
          </h1>
          <p class="desc">The pay-as-you-go social scientific research application.</p>
          <div class="row m-2">
            <a
              class="m-1 col-3 btn btn-custom-size btn-lg extbot-primary-5 blackcurrant-hover"
              href="https://play.google.com/store/apps/details?id=com.itargs.research"
              target="_blank"
              rel="noreferrer"
              >Google play</a
            >

            <a
              class="m-1 col-3 btn btn-custom-size btn-lg extbot-primary-5 blackcurrant-hover"
              href="https://apps.apple.com/us/app/gamechanger-research/id1669525099"
              target="_blank"
              rel="noreferrer"
              >Apple Store</a
            >
          </div>
        </div>
        <div class="hero-img">
          <div class="single-img">
            <img src="images/hero/inner-img/5-2-754x545.png" alt="Hero Image" />
          </div>
          <div class="hero-sticker-wrap">
            <div class="scene fill">
              <div class="expand-width" data-depth="0.2">
                <div class="hero-sticker"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Hero Area End Here -->
</template>
<script>
export default {
  data() {
    return {
      bgImage: "images/hero/bg/5-1-1920x1027.png",
    };
  },
};
</script>
