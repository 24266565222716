<template>
  <div class="banner-area banner-style-10">
    <div class="container banner-container-2">
      <div class="row">
        <div class="col-lg-3 row">
          <div class="">
            <img
              width="600px"
              height="250"
              src="images/banner/10-1-677x5981.png"
              alt="Banner"
            />
          </div>

          <div class="">
            <img
              width="400px"
              height="250"
              src="images/banner/10-1-677x5982.png"
              alt="Banner"
            />
          </div>
        </div>
        <div class="col-lg-4 row">
          <div width="400px" height="250" class=""></div>
          <div class="">
            <img
              width="600px"
              height="250"
              src="images/banner/10-1-677x5983.png"
              alt="Banner"
            />
          </div>
        </div>
        <div class="col-lg-5">
          <div class="banner-content">
            <span class="banner-category extbot-primary-5">All psychology research</span>
            <h2 class="banner-title">
              Create surveys, games, questionnaires and more. Reach anyone, anywhere.
            </h2>
            <p class="banner-desc">Enter subject demographics</p>
            <p class="banner-desc mb-0">
              Create surveys right in your dashboard, results are pre-analyzed
            </p>
            <p class="banner-desc">Compensation sent automatically</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
