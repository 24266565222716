<template>
  <!-- Begin Trial Area -->
  <div class="trial-area trial-style-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="trial-item">
            <span class="trial-category">Try before you buy</span>
            <h2 class="trial-title">Start your one-month trial.</h2>

            <div class="trial-btn-wrap">
              <router-link
                to="/contact"
                class="btn btn-custom-size red-color blackcurrant-hover"
                >Sign Up</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Trial Area End Here -->
</template>
