<template>
  <div class="wrapper">
    <router-view></router-view>

    <HeaderNavFive></HeaderNavFive>

    <HeroSectionFive></HeroSectionFive>

    <BannerEight></BannerEight>

    <FeatureSectionFive></FeatureSectionFive>

    <TrialTwo></TrialTwo>

    <BannerNine></BannerNine>

    <TestimonialFive></TestimonialFive>

    <NewsletterFour
      newslatterFour="newsletter-area style-4 newsletter-space-top-n140"
    ></NewsletterFour>

    <FaqSectionTwo></FaqSectionTwo>

    <FooterSectionFive></FooterSectionFive>
  </div>
</template>

<script>
import HeaderNavFive from "@/components/HeaderNavFive.vue";
import HeroSectionFive from "@/components/HeroSectionFive.vue";
import BannerEight from "@/components/BannerEight.vue";
import FeatureSectionFive from "@/components/FeatureSectionFive.vue";
import TrialTwo from "@/components/TrialTwo.vue";
import BannerNine from "@/components/BannerNine.vue";
import PartnerSectionFour from "@/components/PartnerSectionFour.vue";
import PricingPlanFive from "@/components/PricingPlanFive.vue";
import TestimonialFive from "@/components/TestimonialFive.vue";
import NewsletterFour from "@/components/NewsletterFour.vue";
import FaqSectionTwo from "@/components/FaqSectionTwo.vue";
import FooterSectionFive from "@/components/FooterSectionFive.vue";

export default {
  components: {
    HeaderNavFive,
    HeroSectionFive,
    BannerEight,
    FeatureSectionFive,
    TrialTwo,
    BannerNine,
    PartnerSectionFour,
    PricingPlanFive,
    TestimonialFive,
    NewsletterFour,
    FaqSectionTwo,
    FooterSectionFive,
  },
};
</script>
