<template>
  <div class="banner-area banner-style-11">
    <div class="container banner-container-2">
      <div class="row">
        <div class="col-lg-6">
          <div class="banner-content">
            <span class="banner-category">Easy to work with</span>
            <h2 class="banner-title">Unique & clean interface</h2>
            <p class="banner-desc">
              Create clean surveys in minutes. Instantly appears for participants on their
              mobile application. Make payment sent automatically after data confirmed by
              you.
            </p>
            <div class="banner-btn-wrap">
              <router-link
                to="/contact"
                class="btn btn-custom-size btn-lg extbot-primary-5 blackcurrant-hover"
                >Sign Up</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="banner-img">
            <div class="banner-sticker-wrap style-1">
              <div class="scene fill">
                <div class="expand-width" data-depth="0.2">
                  <div class="banner-sticker"></div>
                </div>
              </div>
            </div>
            <div class="single-img">
              <img src="images/banner/10-1-677x5981.png" alt="Banner" />
            </div>
            <div class="banner-sticker-wrap style-2">
              <div class="scene fill">
                <div class="expand-width" data-depth="-0.2">
                  <div class="banner-sticker"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script></script>
