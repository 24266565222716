<template>
  <!-- Begin Feature Area -->
  <div id="feature" class="feature-area feature-style-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-area">
            <h2 class="section-title">Advanced features</h2>
            <p class="section-desc">
              Keep all your data in one, secure place. Delete anytime. Download as .csv,
              .xls, or .sav files.
            </p>
          </div>
          <swiper
            class="swiper-container feature-slider"
            :breakpoints="swiperOptions.breakpoints"
            :slides-per-view="3"
            :spaceBetween="30"
            :loop="true"
            :pagination="swiperOptions.pagination"
            @swiper="onSwiper"
          >
            <swiper-slide
              v-for="(slider, sliders) in sliders"
              :key="sliders"
              class="feature-item"
            >
              <div class="feature-icon">
                <img :src="`${slider.imgSrc}`" :alt="slider.alt" />
              </div>
              <div class="feature-content">
                <h3 class="title">{{ slider.slidersubTitle }}</h3>
                <p class="desc">{{ slider.sliderDesc }}</p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
            centeredSlides: true,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
      },
      sliders: [
        {
          imgSrc: "images/feature/icon/5-1-47x47.png",
          alt: "Feature Icon",
          slidersubTitle: "Reaction Times",
          sliderDesc: "Record Precise Reaction Times",
        },
        {
          imgSrc: "images/feature/icon/5-3-46x51.png",
          alt: "Free Update",
          slidersubTitle: "Supports languages",
          sliderDesc: "In Arabic, English, other languages.",
        },
        {
          imgSrc: "images/feature/icon/5-3-46x51.png",
          alt: "Feature Icon",
          slidersubTitle: "Play Audio,Video",
          sliderDesc: "Play Audio and Video files.",
        },
        {
          imgSrc: "images/feature/icon/5-3-46x51.png",
          alt: "Feature Icon",
          slidersubTitle: "User Friendly",
          sliderDesc: "Easy to add researches.",
        },
      ],
    };
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
};
</script>
